<template>
  <div>
    <Row class="p-t-10">
          <i-col span="24">
              <p class="remark p-b-5">* 通过画面内容识别，自动寻找当前画面在刊点位情况</p>
              <p v-if="uploadImgUrl&&uploadImgUrl!==''">
                <img style="cursor: pointer;" :src="uploadImgUrl + '?x-oss-process=image/resize,w_150'" @click="visiblePreview=true"/>
                <a @click="handleRefresh" class="m-l-5"><Icon type="md-refresh" />重新识别</a>
              </p>
              <p v-else-if="uploadImgUrl===''" class="text-center text-orange p-t-5">抱歉，未找到匹配的素材库！</p>
              <Upload class="m-l-5" action="" style="width:100px"
                :format="['jpg','jpeg','png']"  accept='image'
                :before-upload="handleBeforeUploadFile" >
                  <Button size="small" type="success" icon="ios-cloud-upload-outline">上传识别画面</Button>
              </Upload>
          </i-col>
      </Row>

      <Modal v-model="visiblePreview" width="1200" footer-hide>
          <img v-if="visiblePreview" :src="uploadImgUrl+'?x-oss-process=image/resize,w_968'" style="width: 100%">
      </Modal>

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>图片识别中...</div>
      </Spin>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'

export default {
  data () {
    return ({
      uploadImgUrl: null,
      uploadFileKey: '',
      visiblePreview: false,
      spinShow: false
    })
  },
  methods: {
    handleBeforeUploadFile (file) {
      this.spinShow = true
      const param = new FormData()
      param.append('file', file)

      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v3/publishstatistical/getfilekeybyfile',
        param,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        this.spinShow = false
        if (res.data.fileKey === '') {
          this.uploadImgUrl = ''
        } else {
          this.uploadImgUrl = res.data.url
          this.uploadFileKey = res.data.fileKey

          this.handleRefresh()
        }
      })

      return false
    },
    handleRefresh () {
      this.$store.commit('set_statistic_adType', null)
      this.$store.commit('set_statistic_advertiserId', null)
      this.$store.commit('set_statistic_brandId', null)
      this.$store.commit('set_statistic_fileId', this.uploadFileKey)
      this.$store.commit('set_statistic_update', new Date())
    }
  }
}
</script>
