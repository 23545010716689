<template>
    <div>
      <Divider dashed style="margin-bottom: 0px;"><span class="divider-text">操作工具</span></Divider>
      <export-demand-file ref="exportDemandFile"></export-demand-file>
      <schedule-statistice/>
      <image-recognition></image-recognition>

      <AssetStatistic v-show="mediaType===0"/>
      <!-- <Tabs value="name1">
        <TabPane label="在刊概况" name="name1">
          <RealTimeCount/>
        </TabPane>
        <TabPane label="合同在刊" name="name2">
          <ContractCount/>
        </TabPane>
    </Tabs> -->
    </div>
</template>

<script>
import ExportDemandFile from '../rightCommon/ExportDemandFile'
import ScheduleStatistice from '../rightCommon/ScheduleStatistice'
import ImageRecognition from '../rightCommon/ImageRecognition'
import AssetStatistic from '../rightCommon/AssetStatistic'
// import RealTimeCount from '../rightCommon/RealTimeCount'
// import ContractCount from '../rightCommon/ContractCount'

export default {
  components: {
    ExportDemandFile, ScheduleStatistice, ImageRecognition, AssetStatistic
    // RealTimeCount, ContractCount
  },
  computed: {
    mediaType () {
      return this.$store.state.statistic.mediaType
    }
  }
}
</script>
